import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'

export function TitleAndMetaTags({
  url = false,
  pathname = '',
  title = 'Appleville Live',
  description = '',
  image = false,
  keywords = ['Appleville Live', 'A. G. Cook', 'PC music'],
}) {
  const { config } = useStaticQuery(graphql`
    query TitleAndMetaTags {
      config: configYaml {
        siteUrl
      }
    }
  `)

  return (
    <Helmet>
      <title>{title ? `${title} | ` : ''}Appleville</title>
      <link rel="icon" href={`favicon.ico`} />
      <link rel="icon" type="image/png" sizes="32x32" href={`favicon-32x32.png`} />
      <link rel="icon" type="image/png" sizes="16x16" href={`favicon-16x16.png`} />

      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />

      <meta name="description" content={keywords.join()} />
      <meta name="keywords" content={keywords.join()} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${config.siteUrl}/assets/social-1.png`} />
      <meta property="og:url" content={'https://appleville.live'} />
      <meta property="og:type" content="website"></meta>
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  )
}

export const query = graphql`
  fragment PageMetaFragmentMarkdown on MarkdownRemark {
    frontmatter {
      title
      keywords
      description
      path
    }
  }
`
