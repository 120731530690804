import styled from 'styled-components'

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  a {
    text-decoration: none !important;
  }
`

export const Button = styled.div`
  background: rgb(0, 28, 255);
  background: linear-gradient(
    33deg,
    rgba(0, 28, 255, 1) 0%,
    rgba(21, 128, 255, 1) 56%,
    rgba(255, 255, 255, 1) 100%
  );

  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding: 10px 30px;
  margin: 40px 10px;

  text-align: center;
  font-size: 2em;
  background-size: cover;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.media.largePhone`
    width: 90vw;
    margin: 20px 10px;
    max-width: 90vw;

  `}
`
export const GreenButton = styled(Button)`
  background: rgba(0, 255, 0, 0.25);
  box-shadow: ${({ flat }) => flat ? '' : '0px 0px 30px rgba(0, 255, 0, 0.75)'};
  border: ${({ flat }) => flat ? '' : '5px groove rgba(0, 255, 0, 1)'};
  color: white;
`