import { css, keyframes } from 'styled-components'

export const colors = {
  bgColor: '#1f1f1f',
  primary: '0075A9',

  white: '#fff',
  
  blue: '#0075A9',
  darkblue: '#253746',
  
  magenta: '#CE0F69',
  gold: '#FF9800' ,
  green: '#00AB84',
  lime: '#C4D600' ,
  purple: '#8031A7',
}
  
export const sizes = {
  giant: 1170,
  desktop: 990,
  tablet: 700,
  largePhone: 500,
  phone: 376,
  mobileHeaderWitdh: 1100,
  maxWidth: 1100,
  maxWidthCentered: 950,
}

export const media = Object.keys(sizes).reduce((accumulator, label) => {
  const emSize = sizes[label] / 16

  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)};
    }
`
  return accumulator
}, {})


const animations = {
  pulse : keyframes`
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(5);
      opacity: 0;
    }
    100% {
      transform: scale(5);
      opacity: 0;
    }
  `,
  fadeIn : keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `,
  fadeInDown : keyframes`
    from {
      opacity: 0;
      transform: translateY(-5px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  `
}

export const theme = {
  colors,
  media,
  sizes,
  animations
}
