import styled from 'styled-components'

export const Wrapper = styled.section`
  align-items: ${({alignment}) => alignment ? alignment : 'center' };
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  padding: 0;
  position: relative;
  background-color: ${({ theme, backgroundColor }) => backgroundColor ? theme.colors[backgroundColor] : 'transparent'};



  ${(props) =>
    props.centered
      ? `
    text-align: center;
  `
      : ``};
`

export const SmallWrapper = styled(Wrapper)`
  max-width: ${(props) => props.theme.sizes.maxWidthCentered}px;
`

export const LargeWrapper = styled(Wrapper)`
  max-width: 1200px;
`

export const FullWidthWrapper = styled(Wrapper)`
  max-width: 100vw;
`

export const HeaderWrapper = styled(Wrapper)`
  max-width: 1400px;
  padding: 0 2rem;
  margin-top: 0;
`

export const HeroWrapper = styled(FullWidthWrapper)`
  padding: 260px 30px;
`

export const SmallHeroWrapper = styled(FullWidthWrapper)`
  padding: 260px 0 130px 0;
`

export const FooterWrapper = styled(FullWidthWrapper)`
  padding: 0;
`