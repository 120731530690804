import styled from 'styled-components'
import applelandImage from '../../static/assets/appleland.png'

export const AppleLand = styled.div`
  display: block;
  background-image: url(${applelandImage});
  background-size: cover;
  background-position: top center;
  height: 25vh;
  z-index: 1333333337;
  width: 100vw;
  position: relative;
  margin-top: -100px;

  transform: scaleY(-1);
`
